<template>
  <div class="new-password-modal">
    <h2>Nueva clave</h2>
    <FormWrapper autocomplete="off" :error="error">
      <FormRow>
        <FormInput ref="code" minlength="16" label="Código de verificación" v-model="code" />
      </FormRow>
      <FormRow>
        <FormPassword ref="password" compare="password2" autocomplete="new-password" label="Contraseña"
          v-model="password">
        </FormPassword>
      </FormRow>
      <FormRow>
        <FormPassword ref="password2" compare="password" autocomplete="new-password" label="Repetir contraseña"
          v-model="password2">
        </FormPassword>
      </FormRow>
      <FormActions>
        <button type="button" v-on:click="cancel()">
          Cancelar
        </button>
        <button type="button" v-on:click="submit()" class="primary">Guardar</button>
      </FormActions>
    </FormWrapper>
  </div>
</template>

<script>
//import store from "@/store";
//import PasswordVisibility from "./component/PasswordVisibility.vue";
import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "NewPassModal",
  id: "new-pass-modal",
  mixins: [FormWrapperMixin],
  data() {
    return {
      el: "newpass",
      password: "",
      password2: "",
      code: ""
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.reject("Cancelled new pass");
    },
    submit() {
      if (this.validate()) {
        this._submit();
      }
    },
    _submit() {
      var self = this;
      const store = useAuthStore();
      store.saveNewPass(this.email, this.password, this.code)
        .then(function (msg) {
          console.log("new pass ok", msg);
          self.$notifications.success("Listo! Ahora puedes ingresar");
          self.resolve(msg);
        })
        .catch(function (error) {
          self.error = error;
        });
    },
  },
};
</script>

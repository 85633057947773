<template>
    <div class="loadmore">
        <!--button @click="load()">Cargar más resultados</button-->
        <button :disabled="disablePrev()" @click="prev()">Anterior</button>
        <button :disabled="disableNext()" @click="next()">Siguiente</button>
    </div>
</template>

<script>
export default {
    name: "LoadMore",
    props: {
        limit: {
            type: Number,
            default: 12
        },
        results: {
            type: Number
        },
        page: {
            type: Number,
            default: 1
        }
    },
    emits: ['loadMore', 'loadPrev', 'loadNext'],
    methods: {
        disablePrev() {
            return this.page === 1;
        },
        disableNext() {
            return this.results !== this.limit;
        },
        show() {
            return this.results === this.limit;
        },
        load() {
            this.$emit('loadMore');
        },
        next() {
            this.$emit('loadNext');
        },
        prev() {
            this.$emit('loadPrev');
        }
    }
}
</script>

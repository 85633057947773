<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <input ref="control" :class="css" type="number" :value="modelValue" @input="emitValue" v-bind="$attrs" />
    </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    name: "FormNumber",
    inheritAttrs: false,
    mixins: [FormControlMixin],
    components: {
        FormControlWrapper
    }
}
</script>
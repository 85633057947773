<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <slot></slot>
        </ol>
    </nav>
</template>

<script>
export default {
    name: "BreadCrumb"
}
</script>
import { defineStore } from 'pinia'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: null,
    all: {},
    active: {}
  }),
  getters: {
    me: state => state.me ? state.me : false,
    username: state => state.me ? state.me.name : false,
  },
  actions: {
    load() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`profile`).then(function(response) {
          self.profile = response.data;
          resolve(response.data)
        }).catch(function(err) {
          reject(err.response.data)
        });
      })
    },
    logout() {
      this.profile = false;
    },
    update(data) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.put(`profile`, data).then(function(response) {
          self.load()
          resolve(response.data)
        }).catch(function(err) {
          reject(err.response.data)
        });
      })
    }
  }
});

<template>
    <li class="breadcrumb-item">
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: "BreadCrumbItem"
}
</script>
<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <div v-for="option in options" :key="option.id" class="form-radio-wrapper">
            <input 
                name="radio[]"
                type="radio"
                :value="option.value" @input="emitValueRadio" :id="option.id"
                ref="control"
                v-bind="$attrs" />
            <label class="form-radio-label" :for="option.id">
                {{ option.label }}
            </label>
        </div>
    </FormControlWrapper>
</template>

<script>
/*:ref="option.id"*/
/*:value="modelValue"*/
import { toRaw } from 'vue';
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    name: "FormRadio",
    mixins: [FormControlMixin],
    components: [
        FormControlWrapper
    ],
    props: {
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        //:checked="isChecked(option.value)"
        isChecked(value) {
            console.log('isChecked', this.modelValue, value);
            let arr = toRaw(this.modelValue);
            return arr.includes(value);
        }
    }
}

</script>
<template>
  <div id="register-verify-modal">
    <h2>Verificación de correo electrónico</h2>
    <FormWrapper :error="error">
      <FormRow>
        <FormInput label="Código" ref="code" v-model="code" placeholder="Código enviado al correo electrónico" />
      </FormRow>
      <FormActions>
        <button type="button" v-on:click="cancel()">
          Cancelar
        </button>
        <button type="button" @click="verify()" class="primary">Verificar</button>
      </FormActions>

      <p>Si el código enviado al correo electrónico no llegó, y ya reviso la carpeta Spam, puede solicitar un nuevo
        código:</p>
      <FormActions>
        <button type="button" class="primary" @click="newcode()">Solicitar nuevo codigo</button>
      </FormActions>
    </FormWrapper>
  </div>
</template>

<script>
import { useAuthStore } from '../stores/auth';
import { FormWrapperMixin } from "@linkedcode/form-wrapper";

export default {
  name: "RegisterVerifyModal",
  mixins: [FormWrapperMixin],
  id: "register-verify-modal",
  data: function() {
    return {
      errors: [],
      code: ""
    };
  },
  computed: {
    store() {
      return useAuthStore(this.$pinia);
    }
  },
  props: {
    email: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true,
    }
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    newcode() {
      const self = this;
      //console.log('new code');
      this.store.code(self.email)
        .then(function(msg) {
          self.$notifications.success("Código enviado");
        })
        .catch(function(err) {
          self.error = err.response.data;
        });
    },
    verify() {
      this.errors = [];
      const self = this;
      this.store.verify(self.email, self.password, self.code)
        .then(function(msg) {
          self.resolve(msg);
        })
        .catch(function(err) {
          console.log('VerifyModal', 'error');
          self.error = err.response.data;
        });
    }
  }
};
</script>
